import { authHeader, subDomain } from '../common/Common';
//CopyPaymentLink
//async function CopyPaymentLink(paymentid) {
//    var btn = document.getElementById(paymentid);

//    btn.innerHTML = 'copied';

//    var paymentlink = BaseUrl + '/make-payment?PaymentId=' + paymentid;
//    var dummy = document.createElement('input'), text = paymentlink;
//    document.body.appendChild(dummy);
//    dummy.value = text;
//    dummy.select();
//    document.execCommand('copy');
//    dummy.style.display = "none";
//    var btns = document.getElementsByClassName("copylinkbutton");
//    for (var i = 0; i < btns.length; i++) {
//        if (btns[i].id !== paymentid)
//            btns[i].innerhtml = 'copy link';
//    }
//}

//GetAllPaymentLinks
async function GetAllPaymentLinks(searchMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetAllPaymentLinks', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//SavePaymentLink
async function SavePaymentLink(PaymentLinksMdl) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(PaymentLinksMdl)
    };
    const GetResponse = await fetch('/api/payment/SavePaymentLink', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//SavePaymentLink
async function SavePaymentLinkIframe(PaymentLinksMdl) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(PaymentLinksMdl)
    };
    const GetResponse = await fetch('/api/payment/SavePaymentLinkIframe', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//ModifyPaymentLink
async function ModifyPaymentLink(PaymentLinksMdl) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(PaymentLinksMdl)
    };
    const GetResponse = await fetch('/api/payment/ModifyPaymentLink', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//SavePaymentStatus
//async function SavePaymentStatus(PaymentStatusMdl) {
//    let UserDetails = authHeader();
//    if (UserDetails !== null) {
//        const requestOptions = {
//            method: 'POST',
//            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
//            body: JSON.stringify(PaymentStatusMdl)
//        };
//        const GetResponse = await fetch('/api/payment/SavePaymentStatusWithAutoSave', requestOptions)
//            .then((response) => {
//                return response.json();
//            });
//        return GetResponse;
//    }
//    else {
//        const requestOptions = {
//            method: 'POST',
//            headers: { 'Content-Type': 'application/json' },
//            body: JSON.stringify(PaymentStatusMdl)
//        };

//        const GetResponse = await fetch('/api/payment/SavePaymentStatus', requestOptions)
//            .then((response) => {
//                return response.json();
//            });
//        return GetResponse;
//    }
//}

//UpdatePaymentLinkExpiry
async function UpdatePaymentLinkExpiry(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/UpdatePaymentLinkExpiry/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function UpdateAutoPay(UpdateAutoPayMld) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(UpdateAutoPayMld)
    };
    const GetResponse = await fetch('/api/payment/UpdateAutoPay', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetPaymentLinksById
async function GetPaymentLinksById(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };
    const GetResponse = await fetch('/api/payment/GetPaymentLinksById/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetCrossRefValueByAisRefKey
async function GetCrossRefValueByAisRefKey(AisRefKeys) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(AisRefKeys)
    };
    const GetResponse = await fetch('/api/payment/GetCrossRefValueByAisRefKey', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetCustomerDetailbyAisAccountNumber(AisAccountNumber) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };
    const GetResponse = await fetch('/api/payment/GetCustomerDetailbyAisAccountNumber/' + AisAccountNumber, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetRemainingBalance(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };
    const GetResponse = await fetch('/api/payment/GetRemainingBalance/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetPolicyFees(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };
    const GetResponse = await fetch('/api/payment/GetPolicyFees/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetPolicyPremium(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };
    const GetResponse = await fetch('/api/payment/GetPolicyPremium/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetTextActiveByPaymentId
async function GetTextActiveByPaymentId(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetTextActiveByPaymentId/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetEmailActiveByPaymentId(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetEmailActiveByPaymentId/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//CheckPremiumFinance
async function CheckPremiumFinance(SubDomainId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/CheckPremiumFinance/' + SubDomainId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function CheckDirectBilling(SubDomainId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/CheckDirectBilling/' + SubDomainId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
} 

async function CheckMultiplePolicies(SubDomainId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/CheckMultiplePolicies/' + SubDomainId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetTransIdByPaymentId(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetTransIdByPaymentId/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
// get user payment profile 
async function GetPaymentProfile() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/payment/GetPaymentProfile', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetPaymentProfileByUserId(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(userId)
    };
    const GetResponse = await fetch('/api/payment/GetPaymentProfile', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Upsert Payment Profile
async function UpsertPaymentProfile(PaymentProfileMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}`},
        body: JSON.stringify(PaymentProfileMdl)
    };
    const GetResponse = await fetch('/api/payment/UpsertPaymentProfile', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function UpsertPaymentProfileByUserId(PaymentProfileMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}`},
        body: JSON.stringify(PaymentProfileMdl)
    };
    const GetResponse = await fetch('/api/payment/UpsertPaymentProfileByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Send PaymentLinks
async function SendPaymentLink(paymentLinkMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(paymentLinkMdl)
    };
    const GetResponse = await fetch('/api/payment/SendPaymentLink/', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Cancel PaymentLinks
async function CancelPaymentLink(paymentId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    
    const GetResponse = await fetch(`/api/payment/CancelPaymentLink/${paymentId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Send PaymentLinks
async function SendTextPaymentLink(paymentLinkMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(paymentLinkMdl)
    };
    const GetResponse = await fetch('/api/payment/SendTextPaymentLink/', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Cancel PaymentLinks
async function CancelStetsonPaymentLink(paymentId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };

    const GetResponse = await fetch(`/api/payment/CancelStetsonPaymentLink/${paymentId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Reactivate PaymentLinks
async function ReactivateStetsonPaymentLink(paymentId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };

    const GetResponse = await fetch(`/api/payment/ReactivateStetsonPaymentLink/${paymentId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Upsert Set Commission percent
async function SetCommission(userCommissionMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(userCommissionMdl)
    };
    const GetResponse = await fetch('/api/payment/SetCommission', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetAllPaymentLinks
async function GetCommissionList(searchMdl, subDomainId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetCommissionList?subDomainId=' + subDomainId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetCommissionById
async function GetCommissionById(CommissionId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/payment/GetCommissionById?CommissionId=' + CommissionId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//UpdateCommission
async function UpdateCommission(userCommissionMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(userCommissionMdl)
    };
    const GetResponse = await fetch('/api/payment/UpdateCommission', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//DeleteCommissionById
async function DeleteCommissionById(CommissionId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/payment/DeleteCommissionById/?CommissionId=' + CommissionId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetLogsByPaymentId
async function GetLogsByPaymentId(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    };
    const GetResponse = await fetch('/api/payment/GetLogsByPaymentId/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllInsuredPoliciesByUserId
async function GetAllInsuredPoliciesByUserId(searchMdl, UserId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetAllInsuredPoliciesByUserId?UserId=' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllMethodofPayments
async function GetAllMethodofPayments(paymentId, emailAddress) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    emailAddress = emailAddress?.replaceAll("+", "%2B");
    const GetResponse = await fetch(`/api/payment/GetAllMethodofPayments/?paymentId=${paymentId}&emailAddress=${emailAddress}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllInsuredPoliciesByCommonUsersId
async function GetAllInsuredPoliciesByCommonUsersId(searchMdl, UserId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetAllInsuredPoliciesByCommonUsersId?UserId=' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllCancelledAccountsByCommonUsersId
async function GetAllCancelledAccountsByCommonUsersId(searchMdl, UserId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetAllCancelledAccountsByCommonUsersId?UserId=' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllPastDueAccountsByCommonUsersId
async function GetAllPastDueAccountsByCommonUsersId(searchMdl, UserId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetAllPastDueAccountsByCommonUsersId?UserId=' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllActiveAccountsByCommonUsersId
async function GetAllActiveAccountsByCommonUsersId(searchMdl, UserId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/payment/GetAllActiveAccountsByCommonUsersId?UserId=' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAccountDetails
async function GetAccountDetails(UserId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetAccountDetails/' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetAccountPastDue
async function GetAccountPastDue(UserId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetAccountPastDue/' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetActiveAccounts
async function GetActiveAccounts(UserId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetActiveAccounts/' + UserId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetAllInsuredPoliciesByPaymentId
async function GetAllInsuredPoliciesByPaymentId(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetAllInsuredPoliciesByPaymentId/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetDownPaymentPaidStatus(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetDownPaymentPaidStatus/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//async function ChargePayment(stipePaymentMdl) {
//    const requestOptions = {
//        method: 'POST',
//        headers: { 'Content-Type': 'application/json' },
//        body: JSON.stringify(stipePaymentMdl)
//    };
//    const GetResponse = await fetch('/api/stripe/ChargePayment', requestOptions)
//        .then((response) => {
//            return response.json();
//        });
//    return GetResponse;
//}

//GetStripePaymentIntent
async function GetPaymentIntent(PaymentAmount, Name, EmailAddress, PhoneNumber, IsAutoPay, defaultcard) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    EmailAddress = EmailAddress.replaceAll("+", "%2B");
    const GetResponse = await fetch(`/api/stripe/CreatePaymentIntent/?PaymentAmount=${parseInt(PaymentAmount)}&Name=${Name}&EmailAddress=${EmailAddress}&PhoneNumber=${PhoneNumber}&IsAutoPay=${IsAutoPay.IsAutoPay}&defaultcard=${defaultcard}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function PaymentSuccess(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    const GetResponse = await fetch('/api/stripe/PaymentSuccess', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


async function PaymentProcessing(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    const GetResponse = await fetch('/api/stripe/PaymentProcessing', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


async function GetPaymentMethodStripe(PaymentIntentId) {
    const requestOptions = {
        method: 'Get',
        headers: { 'Content-Type': 'application/json' }
    };
    console.log("111111111", PaymentIntentId);
    const GetResponse = await fetch('/api/stripe/GetPaymentMethodStripe/' + PaymentIntentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GeneratePaymentReceipt(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    const GetResponse = await fetch('/api/stripe/GeneratePaymentReceipt', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function PaymentWidgetSuccess(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    
    const GetResponse = await fetch('/api/stripe/PaymentWidgetSuccess', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetStripeCustomer
async function GetStripeCustomer(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/stripe/GetStripeCustomer/' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetStripeCustomer
async function GetStripeCustomerNonAuthorized(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch('/api/stripe/GetStripeCustomerNonAuthorized/' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetInsuredDetails(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/GetInsuredDetails/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function ReactivatePaymentLink(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/payment/ReactivatePaymentLink/' + PaymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetCustomerDetailById(InsuredId, PaymentType) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch(`/api/payment/GetCustomerDetailById/?InsuredId=${InsuredId}&PaymentType=${PaymentType}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetAISAccountInfoById(InsuredId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const response = await fetch('/api/payment/GetAISAccountInfoById/' + InsuredId, requestOptions);
    const GetResponse = await response.json();
    return GetResponse;
}
async function GetCustomerDetailByToken(Token) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const response = await fetch('/api/payment/GetCustomerDetailByToken/' + Token, requestOptions);
    const GetResponse = await response.json();
    return GetResponse;
}

//GetStripePaymentIntent
async function ChangeAutopayMethod(PaymentMethodId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/stripe/ChangeAutopayMethod/?PaymentMethodId=${PaymentMethodId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetStripePaymentIntent
async function ChangeACHAutopayMethod(PaymentMethodId, CustomerId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/stripe/ChangeACHAutopayMethod/?PaymentMethodId=${PaymentMethodId}&CustomerId=${CustomerId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//GetStripePaymentIntent
async function AddACHDebit() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/stripe/AddACHDebit`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Create FortPoint hosted-form

async function GetFPPaymentModel(PaymentAmount, Name, EmailAddress, PhoneNumber, IsAutoPay, Isiframe, PaymentId, InvoiceNumber, PolicyNumber) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    EmailAddress = EmailAddress.replaceAll("+", "%2B");
    const GetResponse = await fetch(`/api/fortpoint/createhostedform/?PaymentAmount=${PaymentAmount}&Name=${Name}&EmailAddress=${EmailAddress}&PhoneNumber=${PhoneNumber}&IsAutoPay=${IsAutoPay.IsAutoPay}&PaymentId=${PaymentId}&InvoiceNumber=${InvoiceNumber}&PolicyNumber=${PolicyNumber}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

// Fpp details save in database on success
async function FppPaymentSuccess(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    const GetResponse = await fetch('/api/fortpoint/PaymentSuccess', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function FppPaymentSuccessACH(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    const GetResponse = await fetch('/api/fortpoint/PaymentSuccessACH', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetPolicyByCompanyName(emailAddress) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    emailAddress = emailAddress.replaceAll("+", "%2B");
    const GetResponse = await fetch(`/api/payment/GetPolicyByCompanyName/?emailAddress=${emailAddress}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetEmbedLinkDocuSign(dociSignMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dociSignMdl)
    };
    const GetResponse = await fetch(`/api/payment/Docusign`, requestOptions)
        .then((response) => {
            return response.json();
        }).then((data) => {
            return data;
        });
    return GetResponse;
}
async function GetPFEmbedLinkDocuSign(paymentLinkMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentLinkMdl)
    };
    const GetResponse = await fetch(`/api/payment/PFDocusign`, requestOptions)
        .then((response) => {
            return response.json();
        }).then((data) => {
            return data;
        });
    return GetResponse;
}

async function SaveSignedStatus(PaymentId, PF, DB) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch(`/api/payment/SignedAgreement?PaymentId=${PaymentId}&PF=${PF}&DB=${DB}`, requestOptions)
        .then((response) => {
            return response.json(); 
        }).then((data) => {
            return data;
        });
    return GetResponse;
}

async function GetAllGCodes() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch(`/api/payment/GetAllGCodes`, requestOptions)
        .then((response) => {
            return response.json(); 
        }).then((data) => {
            return data;
        });
    return GetResponse;
}
async function GetCoverageCodes() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch(`/api/payment/GetCoverageCodes`, requestOptions)
        .then((response) => {
            return response.json(); 
        }).then((data) => {
            return data;
        });
    return GetResponse;
}
async function CheckDocumentSigningStatus(paymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch(`/api/payment/CheckDocumentSigningStatus/${paymentId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


async function GetPaymentSuccessDetail(confimationNumber) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    try {
        const response = await fetch(`/api/payment/GetPaymentSuccessDetail/${confimationNumber}`, requestOptions);
        if (response.ok) {
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                return await response.json();
            } else {
                return null;
            }
        } else {
            console.error(`Error: ${response.status} ${response.statusText}`);
            return null;
        }
    } catch (error) {
        console.error('Fetch error:', error);
        return null;
    }
}

async function AddAgaveCustomer(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentDetails)
    };
    const GetResponse = await fetch('/api/payment/AddAgaveCustomer', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function MakeAISPayment(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentDetails)
    };
    const GetResponse = await fetch('/api/payment/MakeAISPayment', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetAllRetrivePendingPayments(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentDetails)
    };
    const GetResponse = await fetch('/api/payment/GetAllRetrivePendingPayments', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetAllRetriveAccountInfo(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentDetails)
    };
    const GetResponse = await fetch('/api/payment/GetAllRetriveAccountInfo', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
async function GetAllRetrivePaymentAccount(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentDetails)
    };
    const GetResponse = await fetch('/api/payment/GetAllRetrivePaymentAccount', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Update Payment method
async function UpdatePaymentMethod(userInfoMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userInfoMdl)
    };
    const GetResponse = await fetch('/api/payment/UpdatePaymentMethod', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function UpdatePaymentIntent(paymentIntentId, amount, paymentType) {
        const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch(`/api/stripe/UpdatePaymentIntent?PaymentIntentId=${paymentIntentId}&amount=${amount}&paymentType=${paymentType}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse.data / 100;
}

async function GenerateDynamicReceipt(stripePaymentMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePaymentMdl)
    };
    const GetResponse = await fetch('/api/stripe/GenerateDynamicReceipt', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


export {
    GetAllPaymentLinks, SavePaymentLink, GetPaymentLinksById, GetPaymentProfile, UpsertPaymentProfile, SendPaymentLink, SendTextPaymentLink, GetPFEmbedLinkDocuSign, GetPaymentMethodStripe, PaymentProcessing,
    SetCommission, GetCommissionList, GetLogsByPaymentId, GetTransIdByPaymentId, GetAllInsuredPoliciesByUserId, GetAllInsuredPoliciesByPaymentId, CheckDocumentSigningStatus,
    GetTextActiveByPaymentId, GetEmailActiveByPaymentId, GetPaymentIntent, PaymentSuccess, UpdatePaymentLinkExpiry, GetInsuredDetails, DeleteCommissionById, GetCommissionById,
    UpdateCommission, GetAllInsuredPoliciesByCommonUsersId, GetAccountDetails, ModifyPaymentLink, GetAllMethodofPayments, GetAllCancelledAccountsByCommonUsersId, ReactivateStetsonPaymentLink,
    GetAllActiveAccountsByCommonUsersId, GetAllPastDueAccountsByCommonUsersId, ReactivatePaymentLink, GetAccountPastDue, GetActiveAccounts, UpdateAutoPay, CancelStetsonPaymentLink,
    GetRemainingBalance, GetPolicyFees, GetPolicyPremium, GetStripeCustomer, GetStripeCustomerNonAuthorized, ChangeAutopayMethod, AddACHDebit, ChangeACHAutopayMethod, PaymentWidgetSuccess, CancelPaymentLink,
    SavePaymentLinkIframe, GetDownPaymentPaidStatus, CheckPremiumFinance, GetFPPaymentModel, FppPaymentSuccess, FppPaymentSuccessACH, CheckDirectBilling, CheckMultiplePolicies, GetPolicyByCompanyName,
    GetEmbedLinkDocuSign, SaveSignedStatus, GetAllGCodes, AddAgaveCustomer, GetPaymentSuccessDetail, GetAllRetrivePendingPayments, GetAllRetriveAccountInfo, GetAllRetrivePaymentAccount, UpdatePaymentMethod
    , GetAISAccountInfoById, GetCustomerDetailById, GetCustomerDetailByToken, GetCustomerDetailbyAisAccountNumber, MakeAISPayment, GetCrossRefValueByAisRefKey, GeneratePaymentReceipt, GetCoverageCodes, GetPaymentProfileByUserId, UpsertPaymentProfileByUserId,
    GenerateDynamicReceipt, UpdatePaymentIntent
};