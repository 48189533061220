import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Logout, authHeader, subDomain } from '../../common/Common';
import { FlowGroups } from '../../common/Enum';
import {GetAccessModulesByRoleId} from "../../services/AccessModulesServices";
export class InternalLayoutHeader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            UserDetails: authHeader(),
            SubDomain: subDomain(),
            userAccessModules: [],
            DynamicLogoPath: JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo
        };
    }
    
    componentDidMount() {
        if (this.state.UserDetails != null) {
            let RoleIds = this.state.UserDetails.UserRoleIds;
            if (RoleIds != null) {
                this._GetAccessModulesByRoleId(RoleIds);
            }
        }
    }
    htmlDecode(input) {
        var e = document.createElement('span');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    _GetAccessModulesByRoleId = (RoleId) => {
        GetAccessModulesByRoleId(RoleId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ userAccessModules: response.data });
                }
            }
        });
    }
    activateHeader = () => {
        let element = document.getElementById('header-newDesign');
        element.classList.toggle('header-active');
        if (element.classList.contains('header-active')) {
            let menu = document.getElementById('header-menu');
            menu.src = './assets/Content/images/x.png';
            let moduleList = document.getElementById('header-moduleList');
            moduleList.classList.remove('d-none');
            let lowerList = document.getElementById('lowerList');
            lowerList.classList.remove('d-none');
        }
        else {
            let menu = document.getElementById('header-menu');
            menu.src = './assets/Content/images/menu.png';
            let moduleList = document.getElementById('header-moduleList');
            moduleList.classList.add('d-none');
            let lowerList = document.getElementById('lowerList');
            lowerList.classList.add('d-none');
        }
    }
    render() {
        let url = (subDomain() === null || subDomain().SubDomain === 'Xgen') ? "" : "/" + subDomain().SubDomain;
        const { userAccessModules } = this.state;
        return (
            <>
                <nav id="header-newDesign" className="bg-dark navbar-newDesign navbar-expand">
                    <div className="header-images">
                        <NavLink to="#" className={`px-4 w-100 d-block`}>
                                {/*<img src={logoUrl} alt="Company Logo" className={url === 'Xgen' ? 'logo' : 'logo-100p'} />*/}
                        <img id="header-brand" src={this.state.DynamicLogoPath } alt="Company Logo" />
                        </NavLink>
                        <div onClick={() => this.activateHeader()} className="header-menu text-decoration-none text-white pointer-event">
                            <img id="header-menu" src="./assets/Content/images/menu.png" />
                            {FlowGroups.SuperAdmin === authHeader().FlowGroupId &&
                            <>
                            <div className="dropdown" data-bs-popper="none">
                                <NavLink to="#" activeClassName="" id="notifications" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                    </NavLink>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 dropdown-size" aria-labelledby="notifications">
                                        <div className="dropdown-menu-header text-center font-weight-bold p-2 border-bottom">
                                            4 New Notifications
                                        </div>
                                        <div className="list-group">
                                        <NavLink className="list-group-item" activeClassName="" to="#">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle text-danger"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="text-dark">Update completed</div>
                                                        <div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
                                                        <div className="text-muted small mt-1">30m ago</div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        <NavLink activeClassName="" to="#" className="list-group-item" title="">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell text-warning"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="text-dark">Lorem ipsum</div>
                                                        <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                                                        <div className="text-muted small mt-1">2h ago</div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        <NavLink activeClassName="" className="list-group-item" to="#">
                                                <div className="dropdown-menu-footer text-center text-muted">
                                                    Show all notifications
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="dropdown" data-bs-popper="none">
                                <NavLink activeClassName="" to="#" id="message" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square align-middle"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                    </NavLink>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 dropdown-size" aria-labelledby="message">
                                        <div className="dropdown-menu-header text-center font-weight-bold p-2 border-bottom">
                                            4 New Messages
                                        </div>
                                        <div className="list-group">
                                        <NavLink activeClassName="" className="list-group-item" to="#">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle text-danger"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="text-dark">Update completed</div>
                                                        <div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
                                                        <div className="text-muted small mt-1">30m ago</div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        <NavLink activeClassName="" to="#" className="list-group-item">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell text-warning"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="text-dark">Lorem ipsum</div>
                                                        <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                                                        <div className="text-muted small mt-1">2h ago</div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                            <NavLink activeClassName="" className="list-group-item" to="#">
                                                <div className="dropdown-menu-footer text-center text-muted">
                                                    Show all notifications
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="dropdown" data-bs-popper="none">
                                <NavLink onClick={() => Logout()} to="#" id="Logout" aria-expanded="false">
                                        <div className="row g-0 align-items-center">
                                            <div className="col-2">
                                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square align-middle">
                                                    <path d="M2.598 9h-1.055c1.482-4.638 5.83-8 10.957-8 6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5c-5.127 0-9.475-3.362-10.957-8h1.055c1.443 4.076 5.334 7 9.902 7 5.795 0 10.5-4.705 10.5-10.5s-4.705-10.5-10.5-10.5c-4.568 0-8.459 2.923-9.902 7zm12.228 3l-4.604-3.747.666-.753 6.112 5-6.101 5-.679-.737 4.608-3.763h-14.828v-1h14.826z" /></svg>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </>
                        }
                        {FlowGroups.XgenOwner === authHeader().FlowGroupId &&
                            <>
                                <div className="dropdown" data-bs-popper="none">
                                    <NavLink onClick={() => Logout()} to="#" id="Logout" aria-expanded="false">
                                        <div className="row g-0 align-items-center">
                                            <div className="col-2">
                                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square align-middle">
                                                    <path d="M2.598 9h-1.055c1.482-4.638 5.83-8 10.957-8 6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5c-5.127 0-9.475-3.362-10.957-8h1.055c1.443 4.076 5.334 7 9.902 7 5.795 0 10.5-4.705 10.5-10.5s-4.705-10.5-10.5-10.5c-4.568 0-8.459 2.923-9.902 7zm12.228 3l-4.604-3.747.666-.753 6.112 5-6.101 5-.679-.737 4.608-3.763h-14.828v-1h14.826z" /></svg>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </>
                        }
                        </div>
                    </div>
                            {/*<svg version="1.2" viewBox="0 0 40 30" width="20" height="20">*/}
                            {/*    <g>*/}
                            {/*        <g>*/}
                            {/*            <path className="s0" fill="#FFFFFF" d="m37.61 5.63h-34.93c-0.72 0-1.3-0.58-1.3-1.3c0-0.72 0.58-1.3 1.3-1.3h34.93c0.72 0 1.3 0.58 1.3 1.3c0 0.72-0.58 1.3-1.3 1.3z"></path>*/}
                            {/*            <path className="s0" fill="#FFFFFF" d="m37.61 16.14h-34.93c-0.72 0-1.3-0.58-1.3-1.3c0-0.72 0.58-1.3 1.3-1.3h34.93c0.72 0 1.3 0.58 1.3 1.3c0 0.72-0.58 1.3-1.3 1.3z"></path>*/}
                            {/*            <path className="s0" fill="#FFFFFF" d="m37.61 26.64h-34.93c-0.72 0-1.3-0.58-1.3-1.3c0-0.72 0.58-1.3 1.3-1.3h34.93c0.72 0 1.3 0.58 1.3 1.3c0 0.72-0.58 1.3-1.3 1.3z"></path>*/}
                            {/*        </g>*/}
                            {/*    </g>*/}
                            {/*</svg>*/}
                    <div className="header-list">
                        <ul id="header-moduleList" className="list-unstyled w-100 d-none">
                            {(userAccessModules !== null && userAccessModules.length !== 0) &&
                                userAccessModules.map((route, index) => (
                                    route.is_Navbar === true &&
                                    <li className="header-item" key={index}>
                                        <NavLink exact activeClassName="activeHeader-newDesign" to={url + "" + route.moduleURL} className="d-block p-3 ps-4 text-decoration-none">
                                            <span className="nav-img" dangerouslySetInnerHTML={{ __html: this.htmlDecode(route.navBarIcon) }}></span>
                                            <span className="sidebar-text">{route.displayName}</span>
                                        </NavLink>
                                    </li>
                                ))
                            }
                            <>
                            {FlowGroups.Users === authHeader().FlowGroupId &&
                                <li className="header-item">
                                    <NavLink exact activeClassName="activeHeader-newDesign" to={url + '/mysettings'} className=" d-block p-3 ps-4 text-decoration-none">
                                        <img className="nav-img me-2" src="./assets/Content/images/settings.png" />
                                        <span className="sidebar-text">Settings</span>
                                    </NavLink>
                                </li>
                            }
                            </>
                        </ul>
                    </div>
                    <div id="lowerList" className="align-self-end justify-content-start d-none">
                        <ul className="list-unstyled">
                            <li>
                                <NavLink exact activeClassName="activeHeader-newDesign" onClick={() => Logout()} to="abc.com" className="d-block p-3 ps-4 text-decoration-none">
                                    <img className="nav-img me-2" src="./assets/Content/images/log-out.png" />
                                    <span className="sidebar-text">Sign out</span>
                                </NavLink>
                            </li>
                            <hr />
                            <li className="" key="99">
                                <div className="d-block p-3 ps-4 text-decoration-none">
                                    <img className="nav-img me-2" src="./assets/Content/images/Profile-Image.png" />
                                    <span className="sidebar-text text-white">{this.state.UserDetails.FullName}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </>
        );
    }
}
