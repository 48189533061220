import { authHeader } from '../common/Common';
//add Payment method
async function AddPaymentMethod(PaymentProfileMdl) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}`},
            body: JSON.stringify(PaymentProfileMdl)
        };
      const GetResponse = await fetch('/api/paymentAPI/AddPaymentMethod', requestOptions)
            .then((response) => {
                return response.json();
            });
        return GetResponse;
}
//Get Agent Payment Profile By UserId
async function GetAgentPaymentProfileByUserId() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/paymentAPI/GetPaymentProfileByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Get GA Payment Profile By UserId
async function GetGAPaymentProfileByUserId() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/paymentAPI/GetPaymentProfileByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//delete Payment method
async function DeleteAgentPaymentMethod() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    
    };
    const GetResponse = await fetch('/api/paymentAPI/DeletePaymentMethodByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//delete Payment method
async function DeleteCustomerPaymentMethod(CusId, Token) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/paymentAPI/DeleteCustomerPaymentMethod?CusId=${CusId}&Token=${Token}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//delete Payment method
async function DeleteGAPaymentMethod() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }

    };
    const GetResponse = await fetch('/api/paymentAPI/DeletePaymentMethodByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Get Fpp Agent Payment Profile
async function GetFppAgentPaymentProfileByUserId() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/fortpoint/GetPaymentProfileByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//delete Fpp Payment method
async function DeleteFppAgentPaymentMethod() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }

    };
    const GetResponse = await fetch('/api/fortpoint/DeletePaymentMethodByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


export { AddPaymentMethod, GetAgentPaymentProfileByUserId, DeleteAgentPaymentMethod, GetGAPaymentProfileByUserId, DeleteGAPaymentMethod, DeleteCustomerPaymentMethod, GetFppAgentPaymentProfileByUserId, DeleteFppAgentPaymentMethod };